




















































import Vue from 'vue'
import {Component} from 'vue-property-decorator';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import Empleado from '@/entidades/Plantacion/Empleado';
import Formulario from '@/components/Empleado/Formulario.vue';
import FormularioAsignacion from '@/components/Empleado/FormularioAsignacion.vue';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {Mutation, State} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta'

@Component({
    components:{
        TablaGeneral,
        Formulario,
        FormularioAsignacion
    }
})
export default class EmpleadoView extends  Vue
{
    @Mutation('mostrarLoading',{namespace:'sistemaModule'}) mostrarLoading!:Function;
	@Mutation('mostarAlert',{namespace:'sistemaModule'}) mostarAlert!:Function;
    @State('sistemaId',{namespace:'authModule'}) sistemaId!:number;
    //Propiedas y atributos
    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Cedula",sortable:true,value:'documento'},
            {text:"Nombres",sortable:true,value:'nombres'},
            {text:"Apellidos",sortable:true,value:'apellidos'},
            {text:"Vendor Sap",sortable:true,value:'vendorSap'},
            {text:"Proceso",sortable:true,value:'procesoName'},
            {text:"Asignar cuadrilla",sortable:true,value:'campo'},
            {text:"Editar",sortable:true,value:'actions'},
            {text:"Estado",sortable:true,value:'estado'},
    ]
   listadoEmpleado:Empleado[]=[];
   search:string ="";
   titulo:string = "";
   action:number = 1;
   modalAgregarEmpleado:boolean =false;
   modalAgregarEmpleadoName:string = "";
   empleadoModelo: Empleado  = new Empleado();
    abrirModalAgregar()
    {
        this.action = 1 ;
        this.titulo = "Registro de Empleado";
        this.empleadoModelo = new Empleado();
        this.modalAgregarEmpleadoName = "Formulario";
        this.modalAgregarEmpleado = true;
    }
   modalEditar(empleado:Empleado)
   {
       this.empleadoModelo  = empleado;
        this.action = 2;
        this.titulo = "Editar Empleado";
        this.modalAgregarEmpleadoName = "Formulario";
        this.modalAgregarEmpleado = true;
   }
   modalCuadrilla(empleado:Empleado)
   {
       console.log('vamos abrir el modal cuadrillas')
       this.modalAgregarEmpleadoName = "FormularioAsignacion";
       this.empleadoModelo =  empleado;
       this.modalAgregarEmpleado = true;
   }
   cerrarModal()
   {
        this.action = 1 ;
        this.titulo = "Registro de Empleado";
        this.empleadoModelo = new Empleado();
        this.modalAgregarEmpleado = false;
        this.modalAgregarEmpleadoName = "";
   }

   async guardarDb()
   {
       try
       {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = new ResponseGenerico(false,"");
            this.empleadoModelo.sistemaId = this.sistemaId;
            if(this.empleadoModelo != undefined)
                response = this.action == 1 ? await Empleado.Guardar(this.empleadoModelo) : await Empleado.Editar(this.empleadoModelo);


            if(response.isSuccess == true)
            {
                await this.ObtenerEmpleado();
                this.cerrarModal();
            }

            let color:string = response.isSuccess ? 'info' : 'error';
            this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));

       }
       catch(error)
       {
           //@ts-ignore
           this.mostarAlert(new Alerta(error,true,"danger",2000));
       }finally{
            this.mostrarLoading(new Loading(false,""));
        }
   }

   async editarEstado(empleado:Empleado)
   {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = await Empleado.EditarEstado(empleado);

            if(response.isSuccess == true) await this.ObtenerEmpleado();

            let color : string = response.isSuccess == true ? 'info' : 'red';
            this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
        }
        catch(error)
        {      //@ts-ignore
             this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
   }

    //Metodos async
    async ObtenerEmpleado()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = await Empleado.Obtener(this.sistemaId);
            if(response.isSuccess == true)
            {
                this.listadoEmpleado = response.isResult;

            }
            else
            {
                this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
               //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
    }

    get Propiedades()
    {
        if(this.modalAgregarEmpleadoName == "Formulario")
        {
            return {'titulo':this.titulo,'action':this.action,'empleado':this.empleadoModelo , 'sistemaId': this.sistemaId};
        }
        else if(this.modalAgregarEmpleadoName == "FormularioAsignacion")
        {
            return {'modelo':this.empleadoModelo}
        }
    }

   mounted()
   {
       this.modalAgregarEmpleadoName = "";
       this.ObtenerEmpleado();
   }
}
