import PeopleBase from "./PeopleBase";
import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import AsignacionCuadrilla from "./AsignacionCuadrilla";
import { mainAxios } from "@/plugins/axios";
export default class Empleado extends PeopleBase
{
    nombres:string ="";
    apellidos:string="";
    fullName:string="";
    empleadoId:number =0;
    cuadrilla:boolean = true;
    cuadrillaId:number =0;
    sistemaId:number=0;
    procesoId:number =0;
    procesoName:string = "";

    static async Obtener(sistemaId:number):Promise<ResponseCustom<Empleado>>
    {
      return await Obtener<Empleado>(`Empleado/Obtener/${sistemaId}`,mainAxios);
    }

    static async ObtenerSelect():Promise<ResponseCustom<Empleado>>
    {
      return await Obtener<Empleado>("Empleado/ObtenerSelect",mainAxios);

    }

    static async Guardar(empleado:Empleado):Promise<ResponseGenerico>
    {
        return await Save<Empleado>("Empleado/Guardar",empleado,mainAxios);
    }

    static async GuardarAsignacionCuadrilla(empleado:AsignacionCuadrilla):Promise<ResponseGenerico>
    {
        return await Save<AsignacionCuadrilla>("Empleado/AsignacionCuadrilla",empleado,mainAxios);
    }

    static async EditarEstado(empleado:Empleado):Promise<ResponseGenerico>
    {
        return await Editar<Empleado>("Empleado/EditarEstado",empleado,mainAxios);
    }

    static async Editar(empleado:Empleado):Promise<ResponseGenerico>
    {
        return await Editar<Empleado>("Empleado/Editar",empleado,mainAxios);
    }

}
