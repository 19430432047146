export default class AsignacionCuadrilla
{
    empleadoId:number =0;
    cuadrillaId:number =0;

    constructor(empleadoId:number,cuadrillaId:number)
    {
        this.empleadoId = empleadoId;
        this.cuadrillaId = cuadrillaId;
    }
}