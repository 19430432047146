


















































































import Empleado from '@/entidades/Plantacion/Empleado';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue} from 'vuelidate/lib/validators'
import { ProcesoEmpleaodModel,listadoProcesoEmpleado } from '@/entidades/Sistema/SitemaModal';

@Component
export default class FormularioApp extends Vue
{
    @Prop({type:Number,required:true})
    action!:number;

    @Prop({type:String,required:true})
    titulo!:string;


    @Prop({required:true})
    empleado!:Empleado;

    @Prop({required:true})
    sistemaId!:number;


     @Validations()
     validations = {
         documento : {required},
         vendorSap : {required},
         nombres: {required},
         apellidos :{required}
     }
     procesoId:number =0;
     documento:string="";
     vendorSap:string ="";
     apellidos:string ="";
     nombres:string= "";
     email:string = "";
     empleadoId:number=0
     empleadoProceso:Array<ProcesoEmpleaodModel> =[];

     //Metodos
     guardarInfo()
    {
        this.empleado.empleadoId = this.empleadoId;
        this.empleado.nombres = this.nombres;
        this.empleado.apellidos = this.apellidos;
        this.empleado.email = this.email;
        this.empleado.documento = this.documento;
        this.empleado.vendorSap = this.vendorSap;
        this.empleado.procesoId = this.procesoId;
        this.empleado.procesoName = this.empleadoProceso.find(t=>t.procesoId == this.procesoId)?.descripcion!;
       this.$emit('guardar');
    }

     //Propiedas computadas

       //Computed
    get texto()
    {
        if(this.action == 1)
        {
            return "GUARDAR";
        }
        else
        {
            this.documento = this.empleado.documento;
            this.nombres = this.empleado.nombres;
            this.apellidos = this.empleado.apellidos;
            this.email = this.empleado.email;
            this.empleadoId = this.empleado.empleadoId;
            this.vendorSap = this.empleado.vendorSap;
            this.procesoId = this.empleado.procesoId;

            return "EDITAR";
        }
    }

    mounted() {
     this.empleadoProceso = listadoProcesoEmpleado().filter(t=>t.sistemaId == this.sistemaId);
    }
}
