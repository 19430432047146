










































import AsignacionCuadrilla from '@/entidades/Plantacion/AsignacionCuadrilla';
import { Cuadrilla, ObtenerIdTipoCuadrilla} from '@/entidades/Plantacion/Cuadrilla';
import Empleado from '@/entidades/Plantacion/Empleado'
import {Vue,Component,Prop,Watch} from 'vue-property-decorator'

@Component({
    name : 'FormularioAsignacion'
})
export default class FormularioAsignacion extends Vue
{
    @Prop({required:true})
    modelo!:Empleado;


    empleado!:Empleado;

    listado:Array<Cuadrilla> =[];
    cuadrillaId:number =0;
    confirmarDelete:boolean=false;

    async ObtenerListadoCuadrilla()
    {
        try
        {
            let response = await ObtenerIdTipoCuadrilla(1);
            console.log(response);
            if(response.isSuccess == true)
            {
                this.listado = response.isResult;
            }
        }
        catch(error)
        {

        }
    }

    async guardar()
    {
        try
        {

            let response = await Empleado.GuardarAsignacionCuadrilla(new AsignacionCuadrilla(this.empleado.empleadoId,this.cuadrillaId));
            console.log(response);
            if(response.isSuccess  == true)
            {

                this.$store.commit('alert',{
                    position : 'center',
                    color : 'red',
                    text : response.isMessage
                });

                this.$emit('cerrar');
            }
            else
            {
                this.$store.commit('alert',{
                    position : 'center',
                    color : 'red',
                    text : response.isMessage
                });
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    get modificarEmpleado()
    {
        this.empleado = this.modelo;
        return this.modelo;
    }

   @Watch('confirmarDelete')
   actualizarBusqueda(value:boolean,valueOld:boolean)
   {
       console.log('enramos en el watch')
       console.log(value);
       if(value == true)
       {
           this.ObtenerListadoCuadrilla();
       }
   }
   

   mounted() {
     if(this.empleado.cuadrilla == false )
     {
         
         this.confirmarDelete = true;
     }
     else
     {
         this.confirmarDelete = false;
     }   
   }

    


}
