import ModelBase from '@/entidades/Plantacion/ModelBase';
import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '../ResponseGenerico';
import { mainAxios } from "@/plugins/axios";

class Cuadrilla extends ModelBase
{
    cuadrillaId:number=0;
    tipoCuadrillaName:string="";
    tipoCuadrillaId:number = 0;
    contratistaId:number | null  | undefined;;

    constructor(cuadrillaId:number,descripcion:string,estado:boolean,tipoCuadrillaId:number,
        contratistaId:number|null|undefined)
    {
        super();
        this.cuadrillaId = cuadrillaId;
        this.tipoCuadrillaId = tipoCuadrillaId;
        this.estado = estado;
        this.descripcion = descripcion;
        this.contratistaId = contratistaId
    }
}

class CuadrillaPlanificacion
{
    cuadrillaId:number=0;
    cuadrillaName:string="";
    tipoCuadrillaId:number = 0;
    contratistaId:number=0;
    contratistaName:string="";
    nominaName:string="";
    

}

class TipoCuadrilla extends ModelBase
{
    tipoCuadrillaId:number = 0;
}

async function ObtenerTipoCuadrilla()
{
    return await Obtener<TipoCuadrilla>("Cuadrilla/ObtenerTipoCuadrilla",mainAxios);
}

async function ObtenerCuadrilla()
{
    return await Obtener<Cuadrilla>("Cuadrilla/Obtener",mainAxios);
}
async function ObtenerSelect()
{
    return await Obtener<Cuadrilla>(`Cuadrilla/ObtenerSelect/`,mainAxios);
}
async function ObtenerIdTipoCuadrilla(tipoCuadrillaId:number)
{
    return await Obtener<Cuadrilla>(`Cuadrilla/ObtenerIdTipoCuadrilla/${tipoCuadrillaId}`,mainAxios);
}
 async function  GuardarCuadrilla(cuadrilla:Cuadrilla):Promise<ResponseGenerico>
{
    return await Save<Cuadrilla>("Cuadrilla/Guardar",cuadrilla,mainAxios);
} 


async function  EditarCuadrilla(cuadrilla:Cuadrilla):Promise<ResponseGenerico>
{
    return await Editar<Cuadrilla>("Cuadrilla/Editar",cuadrilla,mainAxios);
}

async function  EditarCuadrillaEstado(cuadrilla:Cuadrilla):Promise<ResponseGenerico>
{
    return await Editar<Cuadrilla>("Cuadrilla/EditarEstado",cuadrilla,mainAxios);
}

async function ObtenerCuadrillaPlanificacion()
{
    return await Obtener<CuadrillaPlanificacion>('cuadrilla/obtenercuadrillaplanificacion',mainAxios);
}



export 
{
    Cuadrilla,
    TipoCuadrilla,
    ObtenerCuadrilla,
    ObtenerSelect,
    GuardarCuadrilla,
    EditarCuadrilla,
    EditarCuadrillaEstado,
    ObtenerIdTipoCuadrilla,
    ObtenerTipoCuadrilla,
    CuadrillaPlanificacion,
    ObtenerCuadrillaPlanificacion

}